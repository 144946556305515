

























import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/components/form/fields/abstract-field'

@Component
export default class NumberField extends AbstractField {
  onChange(value: any) {
    if (value.includes(',')) {
      this.value.replace(/,/g, '.')
    }
    if (typeof this.field.minValue !== 'undefined' && Number(value) < this.field.minValue) {
      this.value = this.field.minValue
    }

    if (typeof this.field.maxValue !== 'undefined' && Number(value) > this.field.maxValue) {
      this.value = this.field.maxValue
    }

    this.fieldOnChange()
  }

  fieldOnChange(): void {
    this.field.onChange && this.field.onChange(this.form, this.field.index, this.group, this.rowIndex)
  }
}
